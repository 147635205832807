<template>
  <a-tabs v-model:activeKey="activeKey" class="iss-tab">
    <!--    <a-tab-pane v-for="item in dataArray" :key="item.id" :tab="item.tabName">-->
    <!--      <a-essay-manage :init="item.id" />-->
    <!--    </a-tab-pane>-->

    <a-tab-pane key="1" tab="推广者账号">
      <a-promoter-account />
    </a-tab-pane>
    <a-tab-pane key="2" tab="传播者账号">
      <a-communicator />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { Tabs } from 'ant-design-vue';
import Communicator from './account/communicator';
import PromoterAccount from '@/views/i-radar/account/promoterAccount';
// import fieldApi from '@/api/field';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ACommunicator: Communicator,
    APromoterAccount: PromoterAccount,
    // ABadge: Badge,
    // AEssayManage: essaymanage,
  },
  setup() {
    // const route = useRoute();
    const state = reactive({
      activeKey: '1',
      messageCount: {},
      tabId: '',
      dataArray: [],
    });

    // fieldApi.getListResourceTab('').then(data => {
    //   console.log('data', data)
    //   state.dataArray = data;
    // data.forEach(item => {
    //   state.activeKey = item.id
    //   console.log('item', item)
    //
    // });
    // });

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
.iss-tab {
  :deep(.ant-tabs-bar) {
    margin-left: 16px;
    margin-right: 16px;
  }
}
</style>
